import React, { useEffect } from "react";
import { handleCallback } from "../services/auth";

const CallbackPage = () => {
  useEffect(() => {
    handleCallback()
      .then(() => {
        window.location.href = "/"; // Redirect to home after successful login
      })
      .catch((error) => {
        console.error("Error handling callback", error);
      });
  }, []);

  return <div>Handling login...</div>;
};

export default CallbackPage;
